import React from 'react';
import Icon from '../Icon';
import classNames from 'classnames';
import './mobile-dropdown.scss';
import Button from '../Button';
import Action from '../Resource/Action';

const MobileDropdown = ({ unit }) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const handleToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const handleDropdownToggleById = (dropdownId) => {
    const dropdown = document.getElementById(dropdownId);

    if (
      dropdown.classList.contains(
        'toc-dropdown-mobile__menu-item-cells-dropdown--hidden'
      )
    ) {
      dropdown.classList.remove(
        'toc-dropdown-mobile__menu-item-cells-dropdown--hidden'
      );
    } else {
      dropdown.classList.add(
        'toc-dropdown-mobile__menu-item-cells-dropdown--hidden'
      );
    }
  };
  return (
    <>
      <div className={classNames('toc-dropdown-mobile')} onClick={handleToggle}>
        <div className="toc-dropdown-mobile__unit">{unit.title}</div>
        <div className="toc-dropdown-mobile__unit-title-resources">
          <div className="toc-dropdown-mobile__unit-title">
            {unit.description}
          </div>
          <div
            className={classNames(
              'toc-dropdown-mobile__download-button',
              !showDropdown && 'toc-dropdown-mobile__download-button--hidden'
            )}
          >
            <Button to={unit.completeUnitPath} stopPropagation>
              Complete Unit
            </Button>
          </div>
          <Icon
            name="caratdown"
            className={classNames(
              'toc-dropdown-mobile__icon',
              showDropdown && 'toc-dropdown-mobile__icon--open'
            )}
          />
        </div>
      </div>
      <div
        className={classNames(
          'toc-dropdown-mobile__menu',
          !showDropdown && 'toc-dropdown-mobile__menu--hidden'
        )}
      >
        <div className="toc-dropdown-mobile__menu-item">
          <div className="toc-dropdown-mobile__menu-item-cells">
            <div className="toc-dropdown-mobile__menu-item-cells-title toc-dropdown-mobile__menu-item-cells-title-lesson">
              LESSON
            </div>
            <div className="toc-dropdown-mobile__menu-item-cells-title toc-dropdown-mobile__menu-item-cells-title-description">
              DESCRIPTION
            </div>
            <div className="toc-dropdown-mobile__menu-item-cells-title toc-dropdown-mobile__menu-item-cells-title-download-links">
              DOWNLOAD LINKS
            </div>
            {unit.lessons.map((lesson, k) => (
              <>
                <div className="toc-dropdown-mobile__menu-item-cells-lesson">
                  {lesson.mobileTitle}
                </div>
                <div className="toc-dropdown-mobile__menu-item-cells-description">
                  {lesson.description}
                </div>
                <div className="toc-dropdown-mobile__menu-item-cells-download-links">
                  <div
                    className="toc-mobile__dropdown"
                    onClick={() =>
                      handleDropdownToggleById(lesson.mobileDropdownId)
                    }
                  >
                    {lesson.mobileDownloadTitle} <Icon name="caratdown" />
                  </div>
                </div>
                <div
                  id={lesson.mobileDropdownId}
                  className="toc-dropdown-mobile__menu-item-cells-dropdown toc-dropdown-mobile__menu-item-cells-dropdown--hidden"
                >
                  {lesson.downloadLinks.map((downloadLink, l) => {
                    const isArray = Array.isArray(downloadLink.links);
                    return isArray ? (
                      <div>
                        {downloadLink.links.map((link, m) => {
                          if (link.type === 'video') {
                            return (
                              <Action
                                className="toc-dropdown-mobile__video-link-container"
                                label={link.title}
                                type={link.type}
                                clickType="modal"
                                clickLabel={link.clickLabel}
                                guidSrc={link.guidSrc}
                                videoButtonClassOverride="toc-dropdown-mobile__video-link-button"
                              />
                            );
                          } else {
                            return (
                              <a
                                href={link.url}
                                target={link.target || null}
                                className="toc-mobile__dropdown-content-item"
                                data-click-type={
                                  link.url.includes('docs.google.com')
                                    ? 'File Download'
                                    : null
                                }
                                data-click-name={
                                  link.url.includes('docs.google.com')
                                    ? link.label ||
                                      `${lesson.description} Capture Sheet`
                                    : null
                                }
                              >
                                <Icon
                                  name={link.icon}
                                  className="toc-mobile__dropdown-content-item-icon"
                                />{' '}
                                <span className="toc-mobile__dropdown-content-item-name">
                                  {link.title}
                                </span>
                                <span className="toc-mobile__dropdown-content-item-format">
                                  {link.fileFormat}
                                </span>
                              </a>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <a
                        href={downloadLink.url}
                        target={downloadLink.target || null}
                        className="toc-mobile__dropdown-content-item"
                      >
                        <Icon
                          name={downloadLink.icon || 'download'}
                          className="toc-mobile__dropdown-content-item-icon"
                        />
                        <span className="toc-mobile__dropdown-content-item-name">
                          {downloadLink.title}
                        </span>
                        <span className="toc-mobile__dropdown-content-item-format">
                          {downloadLink.fileFormat}
                        </span>
                      </a>
                    );
                  })}
                  {lesson.captureSheets?.map((captureSheet, m) => (
                    <a
                      href={captureSheet.url}
                      className="toc-mobile__dropdown-content-item"
                      target={captureSheet.target || null}
                      key={m}
                      data-click-type={
                        captureSheet.url.includes('docs.google.com')
                          ? 'File Download'
                          : null
                      }
                      data-click-name={
                        captureSheet.url.includes('docs.google.com')
                          ? captureSheet.label ||
                            `${lesson.description} Capture Sheet`
                          : null
                      }
                    >
                      <Icon
                        name="externallink"
                        className="toc-mobile__dropdown-content-item-icon"
                      />
                      <span className="toc-mobile__dropdown-content-item-name">
                        {captureSheet.title}
                      </span>
                    </a>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileDropdown;
