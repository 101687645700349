import React from 'react';
import './styles.scss';
import MobileDropdown from './MobileDropdown';

const MobileTableOfContents = ({ courses }) => {
  return (
    <div className="toc-mobile">
      {courses.map((course, index) => (
        <>
          {/* Header */}
          <div
            key={index}
            className="toc-mobile__header"
            style={{
              backgroundColor: `${course.background}`,
            }}
          >
            <p className="toc-mobile__header-topic">{course.curriculum} </p>
            <p className="toc-mobile__header-description">
              {course.mobileHeader}
            </p>
          </div>
          {/* Content */}
          <div className="toc-mobile__content">
            <div
              className="toc-mobile__content-description"
              dangerouslySetInnerHTML={{ __html: course.description }}
            />

            {/* Mobile Dropdown */}
            {course.units.map((unit, index) => (
              <MobileDropdown key={index} unit={unit} />
            ))}
          </div>
        </>
      ))}
    </div>
  );
};

export default MobileTableOfContents;
