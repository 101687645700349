import React from 'react';
import './styles.scss';
import Dropdown from './Dropdown';

const TableOfContents = ({ courses }) => {
  return (
    <div className="toc">
      {courses.map((course, index) => (
        <>
          {/* Header */}
          <div
            key={index}
            className="toc__header"
            style={{
              backgroundColor: `${course.background}`,
            }}
          >
            <p className="toc__header-topic">{course.curriculum}</p>
            <p className="toc__header-description">{course.header}</p>
          </div>
          {/* Content */}
          <div className="toc__content">
            <div
              className="toc__content-description"
              dangerouslySetInnerHTML={{ __html: course.description }}
            />

            {/* Dropdown */}
            {course.units.map((unit, index) => (
              <Dropdown key={index} unit={unit} />
            ))}
          </div>
        </>
      ))}
    </div>
  );
};

export default TableOfContents;
