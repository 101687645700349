import React from 'react';
import Icon from '../Icon';
import classNames from 'classnames';
import './dropdown.scss';
import Button from '../Button';
import Action from '../Resource/Action';

const Dropdown = ({ unit }) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [showSubDropdown, setShowSubDropdown] = React.useState(false);
  const handleToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const handleSubToggle = () => {
    setShowSubDropdown(!showSubDropdown);
  };
  return (
    <>
      <div className={classNames('toc-dropdown')} onClick={handleToggle}>
        <div className="toc-dropdown__unit">{unit.title}</div>
        <div className="toc-dropdown__unit-title-resources">
          <div className="toc-dropdown__unit-title">{unit.description}</div>
          <div className="toc-dropdown__unit-resources">
            {unit.duration ? `${unit.duration} | ` : ''}
            {unit.resources.map((resource, j, arr) => (
              <span key={j}>
                {resource}
                {arr.length - 1 !== j ? ', ' : ''}
              </span>
            ))}
          </div>
          <div
            className={classNames(
              'toc-dropdown__download-button',
              !showDropdown && 'toc-dropdown__download-button--hidden'
            )}
          >
            <Button to={unit.completeUnitPath} stopPropagation>
              <Icon name="download" />
              Complete Unit
            </Button>
          </div>
          <Icon
            name="caratdown"
            className={classNames(
              'toc-dropdown__icon',
              showDropdown && 'toc-dropdown__icon--open'
            )}
          />
        </div>
      </div>
      <div
        className={classNames(
          'toc-dropdown__menu',
          !showDropdown && 'toc-dropdown__menu--hidden'
        )}
      >
        <div className="toc-dropdown__menu-item">
          <div className="toc-dropdown__menu-item-cells">
            <div className="toc-dropdown__menu-item-cells-title toc-dropdown__menu-item-cells-title-lesson">
              LESSON
            </div>
            <div className="toc-dropdown__menu-item-cells-title toc-dropdown__menu-item-cells-title-description">
              DESCRIPTION
            </div>
            <div className="toc-dropdown__menu-item-cells-title toc-dropdown__menu-item-cells-title-classes">
              CLASSES
            </div>
            <div className="toc-dropdown__menu-item-cells-title toc-dropdown__menu-item-cells-title-download-links">
              DOWNLOAD LINKS
            </div>
            <div className="toc-dropdown__menu-item-cells-title toc-dropdown__menu-item-cells-title-capture-sheets">
              EDITABLE CAPTURE SHEETS
            </div>

            {unit.lessons.map((lesson, k) => (
              <>
                <div className="toc-dropdown__menu-item-cells-lesson">
                  {lesson.title}
                </div>
                <div className="toc-dropdown__menu-item-cells-description">
                  {lesson.description}
                </div>
                <div className="toc-dropdown__menu-item-cells-classes">
                  {lesson.classes}
                </div>
                <div className="toc-dropdown__menu-item-cells-download-links">
                  {lesson.downloadLinks.map((downloadLink, l) => {
                    const isArray = Array.isArray(downloadLink.links);
                    return isArray ? (
                      <div
                        key={l}
                        className="toc__dropdown"
                        onClick={handleSubToggle}
                      >
                        {downloadLink.title} <Icon name="caratdown" />
                        <div
                          className={classNames(
                            'toc__dropdown-content',
                            !showSubDropdown && 'toc__dropdown-content--hidden'
                          )}
                        >
                          {downloadLink.links.map((link, m) => {
                            if (link.type === 'video') {
                              return (
                                <Action
                                  className="toc-dropdown__video-link-container"
                                  label={link.title}
                                  type={link.type}
                                  clickType="modal"
                                  clickLabel={link.clickLabel}
                                  guidSrc={link.guidSrc}
                                  videoButtonClassOverride="toc-dropdown__video-link-button"
                                />
                              );
                            } else {
                              return (
                                <a
                                  href={link.url}
                                  target={link.target || null}
                                  className="toc__dropdown-content-item"
                                  data-click-type={
                                    link.url.includes('docs.google.com')
                                      ? 'File Download'
                                      : null
                                  }
                                  data-click-name={
                                    link.url.includes('docs.google.com')
                                      ? link.label ||
                                        `${lesson.description} Capture Sheet`
                                      : null
                                  }
                                >
                                  <Icon
                                    name={link.icon}
                                    className="toc__dropdown-content-item-icon"
                                  />{' '}
                                  <span className="toc__dropdown-content-item-name">
                                    {link.title}
                                  </span>
                                  <span className="toc__dropdown-content-item-format">
                                    {link.fileFormat}
                                  </span>
                                </a>
                              );
                            }
                          })}
                        </div>
                      </div>
                    ) : (
                      <a
                        className="toc-dropdown__multi-download-links"
                        href={downloadLink.url}
                        target={downloadLink.target || null}
                      >
                        {downloadLink.title}
                      </a>
                    );
                  })}
                </div>
                <div className="toc-dropdown__menu-item-cells-capture-sheets">
                  {lesson.captureSheets?.map((captureSheet, m) => (
                    <a
                      href={captureSheet.url}
                      target={captureSheet.target || null}
                      key={m}
                      data-click-type={
                        captureSheet.url.includes('docs.google.com')
                          ? 'File Download'
                          : null
                      }
                      data-click-name={
                        captureSheet.url.includes('docs.google.com')
                          ? captureSheet.label ||
                            `${lesson.description} Capture Sheet`
                          : null
                      }
                    >
                      {captureSheet.title}
                    </a>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
