import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import CourseUnitCard from '../../components/CourseUnitCard';
import Hero from '../../components/Hero';
import Subfooter from '../../components/Subfooter';
import '../../styles/pages/_classroom-instruction.scss';
import '../../styles/styles.scss';
import Button from '../../components/Button';
import Video from '../../components/Video';
import TableOfContents from '../../components/TableOfContents';
import TableOfContentsMobile from '../../components/TableOfContentsMobile';
import { graphql } from 'gatsby';

const InstructionalSupportPage = ({ data }) => {
  return (
    <Layout title="Instructional Support">
      <Hero
        mediaSource={'hero-is@2x.jpg'}
        className={'hero--large mb-0 pb-0'}
        color={'blue'}
      >
        <h1>BIOTECH IN THE CLASSROOM</h1>
        <h3>
          Next Generation Science Standards (NGSS) and Career and Technical
          Education (CTE) Aligned Courses{' '}
        </h3>
        <p>
          Instructional materials designed to meet national education and
          industry standards to focus on in-demand skills needed across the full
          product development lifecycle—from molecule to medicine—which expose
          students and educators to the breadth of education and career pathways
          across biotechnology.
        </p>
      </Hero>
      <Section>
        <Container>
          {/* Intro text */}
          <Row>
            <Column size={12}>
              <h5 className="mb-0">BIOTECH</h5>
              <h3 className="living-earth__at-a-glance-header">
                Getting Started
              </h3>
              <p>
                Each course listed below includes flexible topic-based units and
                supplementary instructional resources to equip educators with
                strategies that engage students in the range of biotechnology.
                Use the materials as they best fit into your classroom needs.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      {/** Sizzler Video */}
      <Section className="CI-overview__sizzler-section">
        <Container>
          <Row className="CI-overview__sizzler-video-row">
            <Column size={4}>
              <Video
                className="CI-overview__sizzler-video"
                guidSrc="ee5afec2-4884-4f6a-9cca-02cf21df6771"
                poster="img-Career-Profiles-P-Dhillon@2x.jpg"
                autoPlay={true}
              />
            </Column>
            <Column size={8}>
              <h3 className="CI-overview__header">
                Futurelab+ Overview and Demonstration
              </h3>
              <p>
                Watch this video for a quick overview of the breadth of
                resources available and a demonstration of how to navigate
                through sections with ease.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container fullWidth>
          <TableOfContents courses={data.allContentYaml.nodes} />
          <TableOfContentsMobile courses={data.allContentYaml.nodes} />
        </Container>
      </Section>
      {/* First row of unit cards */}
      <Section>
        <Container fullWidth>
          <Row className="mb-3">
            <Column size={4}>
              <div className="pb-0 CI-overview">
                <div>
                  <div className="CI-overview-header__living-earth">
                    <h3>Living Earth</h3>
                    <h4>Supplementary Curriculum | 6 week Units</h4>
                    <h4>NGSS</h4>
                  </div>
                  <div className="CI-overview-container">
                    <Image filename="" className="CI-overview-image" />
                    <div className="CI-overview-text">
                      <p className="CI-overview-p">
                        Project-based units focused on engaging experiences for
                        students to dive deeply into practical applications of
                        biotech skills and concepts. Some topics covered include
                        genetic variation, natural selection, genes, proteins
                        and tissues, cellular division… and more
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 1 | Inheritance of Traits <br />
                        <span>
                          Genetic Diversity: Investigating inherited diseases
                        </span>
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 2 | Structure, Function and Growth <br />
                        <span>Community Empowerment: Eradicating Disease</span>
                      </p>
                    </div>
                  </div>
                </div>
                <Button
                  to="/classroom-instruction/living-earth/"
                  className="CI-overview-bttn"
                >
                  Go to Living Earth Curriculum
                </Button>
              </div>
            </Column>
            <Column size={4}>
              <div className="pb-0 CI-overview">
                <div>
                  <div className="CI-overview-header__chem">
                    <h3>Chemistry in the Earth System</h3>
                    <h4>Supplementary Resources | 4 Multiday Lessons</h4>
                    <h4>NGSS</h4>
                  </div>
                  <div className="CI-overview-container">
                    <Image filename="" className="CI-overview-image" />
                    <div className="CI-overview-text">
                      <p className="CI-overview-p">
                        Dive into the chemical world beneath the microscope to
                        understand how DNA and molecular biology are being used
                        in biotechnology to reimagine life as we know it.
                      </p>
                      <p className="CI-overview-unit-text">
                        Digital Lesson Bundles <br />
                        <span>Antibody Avengers</span>
                        <br />
                        <span>Benefits of Biologics</span>
                        <br />
                        <span>Cancer and Companion Diagnostics</span>
                        <br />
                        <span>Going Green in Biopharma</span>
                      </p>
                    </div>
                  </div>
                </div>
                <Button
                  to="/classroom-instruction/chemistry/"
                  className="CI-overview-bttn"
                >
                  Go to Chemistry Curriculum
                </Button>
              </div>
            </Column>
            <Column size={4}>
              <div className="pb-0 CI-overview">
                <div>
                  <div className="CI-overview-header__phys">
                    <h3>Physics of the Universe</h3>
                    <h4>Supplementary Resources | 4 Multiday Lessons</h4>
                    <h4>NGSS</h4>
                  </div>
                  <div className="CI-overview-container">
                    <Image filename="" className="CI-overview-image" />
                    <div className="CI-overview-text">
                      <p className="CI-overview-p">
                        Look beyond the everyday to the essential physics
                        principles that help us understand our universe and
                        guide the development of future biotechnology
                        innovations.
                      </p>
                      <p className="CI-overview-unit-text">
                        Digital Lesson Bundles <br />
                        <span>Applying Spectroscopy</span>
                        <br />
                        <span>Generating the Future</span>
                        <br />
                        <span>
                          Riding the Wave of Electromagnetic Radiation
                        </span>
                        <br />
                        <span>Uncovering Plasma's Promise</span>
                      </p>
                    </div>
                  </div>
                </div>
                <Button
                  to="/classroom-instruction/physics/"
                  className="CI-overview-bttn"
                >
                  Go to Physics Curriculum
                </Button>
              </div>
            </Column>
          </Row>
          {/* Second row of unit cards */}
          {/* <Row>
            <Column size={4}>
              <div className="pb-0 CI-overview">
                <div>
                  <div className="CI-overview-header__biomed">
                    <h3>Biomed</h3>
                    <h4>Year 1 Curriculum | 9 Week Units</h4>
                    <h4>CTE / Biotechnology Biomed</h4>
                  </div>
                  <div className="CI-overview-container">
                    <Image filename="" className="CI-overview-image" />
                    <div className="CI-overview-text">
                      <p className="CI-overview-p">
                        Take students to the frontier of modern medicine to
                        learn how biotechnology is helping to target both
                        individual and global needs with everything from
                        wearable tech to pandemic preparedness solutions.
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 1<br />
                        <span>Crowdsourcing innovations in biotech</span>
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 2 <br />
                        <span>
                          Taking Action in Your Community: Health Equity
                        </span>
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 3 <br />
                        <span>
                          Nucleic Acids and Proteins: Disease Treatment
                          Innovations
                        </span>
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 4 <br />
                        <span>
                          Behind the Scenes of Scientific Breakthroughs 
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <Button
                  to="/classroom-instruction/biomed/"
                  className="CI-overview-bttn"
                >
                  Biomed Curriculum
                </Button>
              </div>
            </Column>
            <Column size={4}>
              <div
                className="pb-0 CI-overview"
                style={{
                  backgroundImage: 'url(/images/card-ag-categories@2x.jpg)',
                  backgroundColor: '#1E3610',
                }}
              >
                <div>
                  <div className="CI-overview-header__ag">
                    <h3>AG/Environmental</h3>
                    <h4>Year 2 Curriculum | 9 Week Units</h4>
                    <h4>CTE / Biotechnology AG/Environmental</h4>
                  </div>
                  <div className="CI-overview-container">
                    <Image filename="" className="CI-overview-image" />
                    <div className="CI-overview-text">
                      <p className="CI-overview-p">
                        Investigate the amazing ways that living organisms can
                        be harnessed to improve agriculture processes and
                        redefine a healthier relationship with the natural
                        environment.
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 5<br />
                        <span>SOLUTION SEEKING MICROBES</span>
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 6 <br />
                        <span>ALTERNATIVE PROTEINS</span>
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 7 <br />
                        <span>Plant to Pharmaceutical</span>
                      </p>
                      <p className="CI-overview-unit-text">
                        Unit 8 <br />
                        <span>Community Science</span>
                      </p>
                    </div>
                  </div>
                </div>
                <Button
                  to="/classroom-instruction/ag-environmental/"
                  className="CI-overview-bttn"
                >
                  AG/environmental Curriculum
                </Button>
              </div>
            </Column>
          </Row> */}
          {/* Horizontal unit cards */}
          <CourseUnitCard
            unitIdentifier="NGSS and CTE Curriculum"
            header="Student Interactives"
            description="Let students follow their own interests in biotechnology with these self-guided interactives. Students will explore real-world examples of biotech revolutionizing the field of medicine and improving lives globally."
            img="card-interactives@2x.jpg"
            to="/classroom-instruction/student-interactives"
          />
          <CourseUnitCard
            unitIdentifier="NGSS and CTE Curriculum"
            header="Instructional Resources"
            description="The possibilities for classroom exploration are limitless when you embrace the transdisciplinary connections of biotechnology. Use these research-based educator guides to build curriculum connections and empower students from every background with next-gen STEM problem-solving skills."
            img="card-resources@2x.jpg"
            to="/classroom-instruction/instructional-support"
          />
        </Container>
      </Section>
      {/* Additional Details Summary - Gray area with icons*/}
      <Section className="living-earth__at-a-glance-section pt-2">
        <Container fullWidth className="living-earth__at-a-glance-container">
          <h3 className="living-earth__at-a-glance-header">
            Additional Details
          </h3>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-course-outline.svg" />
              <h4 className="living-earth__at-a-glance-section-header mt-0">
                INSTRUCTIONAL SUPPORT
              </h4>
              <ul className="living-earth__at-a-glance-li">
                <li>Culturally and Linguistically Responsive Instruction</li>
                <li>Connection to the Product Life Cycle</li>
                <li>Computational Thinking Practices</li>
                <li>Standards Alignment</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image
                filename="icon-clip-guiding-principles.svg"
                className="CI-overview-icon"
              />
              <h4 className="living-earth__at-a-glance-section-header mt-2">
                GUIDING PRINCIPLES
              </h4>
              <ul className="living-earth__at-a-glance-li">
                <li>Equity</li>
                <li>Adaptability</li>
                <li>Industry Driven</li>
                <li>Teacher Voice</li>
                <li>Teaching Breadth and Inclusivity</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image
                filename="icon-standards.svg"
                className="CI-overview-icon"
              />
              <h4 className="living-earth__at-a-glance-section-header mt-2">
                STANDARDS
              </h4>
              <ul className="living-earth__at-a-glance-li">
                <li>NGSS Aligned</li>
                <li>CTE Aligned</li>
                <li>Flexible lesson design</li>
                <li>Adaptable to fit classroom needs</li>
                <li>Dual-enrollment opportunities</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-courses.svg" className="CI-overview-icon" />
              <h4 className="living-earth__at-a-glance-section-header mt-2">
                COURSES
              </h4>
              <ul className="living-earth__at-a-glance-li">
                <li>Full Year-Based Curriculum</li>
                <li>Flexible to Grab-and-Go</li>
                <li>Topic-based Lesson Plans</li>
                <li>Student Worksheets</li>
                <li>Student Interactives</li>
                <li>Labs</li>
                <li>Pacing</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pt-0 pb-0">
        <Subfooter content="Biotech is revolutionizing every aspect of life, from advances in medical diagnostics and drug development to improvements in agricultural and food products." />
      </Section>
    </Layout>
  );
};

export default InstructionalSupportPage;

export const query = graphql`
  query {
    allContentYaml(sort: { order: ASC, fields: renderOrder }) {
      nodes {
        units {
          description
          duration
          completeUnitPath
          lessons {
            classes
            description
            title
            mobileTitle
            mobileDownloadTitle
            mobileDropdownId
            downloadLinks {
              target
              title
              url
              fileFormat
              icon
              links {
                fileFormat
                icon
                target
                title
                url
                type
                clickLabel
                guidSrc
                label
              }
            }
            captureSheets {
              target
              title
              url
              label
            }
          }
          resources
          title
        }
        renderOrder
        background
        description
        header
        mobileHeader
        curriculum
      }
    }
  }
`;
